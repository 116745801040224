// extracted by mini-css-extract-plugin
export var active = "header-module--active--XpLlV";
export var button = "header-module--button--MRlxv";
export var container = "header-module--container--i1m8L";
export var content = "header-module--content--bSCt4";
export var hidden = "header-module--hidden--uQUvg";
export var item = "header-module--item--JMpZu";
export var lang = "header-module--lang--9mEvx";
export var list = "header-module--list--MbLmj";
export var logo = "header-module--logo--y97xu";
export var sticky = "header-module--sticky--cDKun";
export var subHeading = "header-module--sub-heading--d-k3q";