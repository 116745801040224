import React, { useEffect } from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Image from "../components/image"
import * as heroStyles from "./hero.module.scss"

export default function Hero(props) {
  let tl = gsap.timeline();
  const bgImage = "[class*='hero-module--bg']";
  const title = "[class*='hero-module--content'] h1";
  const desc = "[class*='hero-module--content'] p";
  const image = "[class*='hero-module--image']";

  function fadeInHero() {
    tl.to(bgImage, {scaleX: 1, scaleY: 1, autoAlpha: 1, ease: "power3.easeOut", duration: 1}, .2)
      .to(title, {y: 0, autoAlpha: 1, ease: "power3.easeOut", duration: 1}, .4)
      .to(desc, {y: 0, autoAlpha: 1, ease: "power3.easeOut", duration: 1}, .6)
      .to(image, {y: 0, autoAlpha: 1, ease: "power3.easeOut", duration: 1}, .8)
  }

  function hideHero() {
    tl.set([title, desc, image], {y: 30, autoAlpha: 0})
      .set(bgImage, {scaleX: 1.05, scaleY: 1.05, autoAlpha: 0});
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    hideHero();

    gsap.delayedCall(0.2, () => {
      ScrollTrigger.create({
        trigger: "[class*='hero-module--container']",
        start: "top 95%",
        onEnter: function() { fadeInHero() },
      })
      ScrollTrigger.refresh()
    })
  })

  return (
    <div className={heroStyles.container}>
        <div className={(props.image ? `${heroStyles.wrapperImage}` : `${heroStyles.wrapperText}`)}>
            <div>
              <div className={heroStyles.bg}>
                {props.image && <Image filename={props.image} alt={props.image} objectFit={props.objectFit} objectPosition={props.objectPosition} />}
              </div>
              {props.image && <div className={heroStyles.image}><Image filename={props.image} alt={props.image} objectFit={props.objectFit} objectPosition={props.objectPosition} /></div>}
            </div>
            <div className={heroStyles.content}>
                <h1>{props.title}</h1>
                {props.description &&<p>{props.description}</p>}
            </div>
        </div>
    </div>
  )
}