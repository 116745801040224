// extracted by mini-css-extract-plugin
export var active = "mobileMenu-module--active--iarm6";
export var button = "mobileMenu-module--button--TeQBr";
export var container = "mobileMenu-module--container--SHA1-";
export var item = "mobileMenu-module--item--y-7Au";
export var lang = "mobileMenu-module--lang--VGBlU";
export var list = "mobileMenu-module--list--8YV+x";
export var logo = "mobileMenu-module--logo--BYOqu";
export var nav = "mobileMenu-module--nav--WGv5Y";
export var subHeading = "mobileMenu-module--sub-heading--z5G0f";
export var wrapper = "mobileMenu-module--wrapper--tuvUN";